@import "vars.scss";

* {
  box-sizing: border-box;
}

::selection {
  color: white;
  background: black;
}

html,
body {
  border: 0;
  padding: 0;
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  font-family: "Monument", courier, monospace;
}

a {
  transition: 300ms all;
}

@font-face {
  font-family: "Monument";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/ABCMonumentGrotesk-Medium.woff2") format("woff2"),
    url("/fonts/ABCMonumentGrotesk-Medium.woff") format("woff");
}

@font-face {
  font-family: "Simon";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/ABCSimonMono-Regular.woff2") format("woff2"),
    url("/fonts/ABCSimonMono-Regular.woff") format("woff");
}

@keyframes loadAnimation {
  0% {
    opacity: 1;
    width: 0%;
  }

  90% {
    opacity: 1;
    width: 100%;
  }

  100% {
    opacity: 0;
    width: 100%;
  }
}

.fadeIn {
  animation: fadeIn 1s forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.shopify-buy__type--center {
  text-align: center;
}
.shopify-buy--visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
/*
.shopify-buy__btn {
  color: #fff;
  font-size: 15px;
  background-color: #78b657;
  padding: 12px 40px;
  letter-spacing: 0.3px;
  display: block;
  border-radius: 3px;
  cursor: pointer;
  -webkit-transition: background 200ms ease;
  transition: background 200ms ease;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.2;
  border: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.shopify-buy__btn:hover,
.shopify-buy__btn:focus {
  background-color: #5f9d3e;
}
.shopify-buy__btn--parent {
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
}
.shopify-buy__btn--parent:hover .product__variant-img,
.shopify-buy__btn--parent:focus .product__variant-img {
  opacity: 0.7;
}*/

.shopify-buy__btn--cart-tab {
  padding: 5px 11px;
  border-radius: 3px 0 0 3px;
  position: fixed;
  right: 0;
  top: 50%;
  -webkit-transform: translate(100%, -50%);
  transform: translate(100%, -50%);
  opacity: 0;
  min-width: inherit;
  width: auto;
  height: auto;
  z-index: 2147483647;
}
.shopify-buy__btn--cart-tab.is-active {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 1;
}
.shopify-buy__btn__counter {
  display: block;
  margin: 0 auto 10px auto;
  font-size: 18px;
}
.shopify-buy__icon-cart--side {
  height: 20px;
  width: 20px;
}
.shopify-buy__btn[disabled] {
  background-color: #999;
  pointer-events: none;
}
.shopify-buy__btn--close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #767676;
  border: none;
  background-color: transparent;
  -webkit-transition: color 100ms ease, -webkit-transform 100ms ease;
  transition: color 100ms ease, -webkit-transform 100ms ease;
  transition: transform 100ms ease, color 100ms ease;
  transition: transform 100ms ease, color 100ms ease,
    -webkit-transform 100ms ease;
  cursor: pointer;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-right: 9px;
}
.shopify-buy__btn--close:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  color: hsl(0, 0%, 41.2745098039%);
}
@-webkit-keyframes flipIn {
  from {
    max-height: 0;
    -webkit-transform: rotatex(90deg) translatey(-50%);
    transform: rotatex(90deg) translatey(-50%);
    margin-bottom: -65px;
    opacity: 0;
  }
  to {
    max-height: none;
    -webkit-transform: none;
    transform: none;
    margin-bottom: 20px;
    opacity: 1;
  }
}
@keyframes flipIn {
  from {
    max-height: 0;
    -webkit-transform: rotatex(90deg) translatey(-50%);
    transform: rotatex(90deg) translatey(-50%);
    margin-bottom: -65px;
    opacity: 0;
  }
  to {
    max-height: none;
    -webkit-transform: none;
    transform: none;
    margin-bottom: 20px;
    opacity: 1;
  }
}
@-webkit-keyframes flipOut {
  from {
    max-height: none;
    -webkit-transform: none;
    transform: none;
    margin-bottom: 20px;
    opacity: 1;
  }
  to {
    max-height: 0;
    -webkit-transform: rotatex(90deg) translatey(-50%);
    transform: rotatex(90deg) translatey(-50%);
    margin-bottom: -65px;
    opacity: 0;
  }
}
@keyframes flipOut {
  from {
    max-height: none;
    -webkit-transform: none;
    transform: none;
    margin-bottom: 20px;
    opacity: 1;
  }
  to {
    max-height: 0;
    -webkit-transform: rotatex(90deg) translatey(-50%);
    transform: rotatex(90deg) translatey(-50%);
    margin-bottom: -65px;
    opacity: 0;
  }
}
.shopify-buy__cart-wrapper {
  height: 100%;
  padding-left: 10px;
}
.shopify-buy__cart {
  height: 100%;
  background-color: #fff;
  width: calc(100% - 10px);
  position: absolute;
  right: 0;
  -webkit-box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
}
.shopify-buy__cart__header {
  padding: 20px;
  padding-right: 40px;
  position: relative;
  z-index: 2147483647;
  background-color: inherit;
}
.shopify-buy__cart__title {
  font-size: 18px;
  color: #767676;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
.shopify-buy__cart-scroll {
  padding: 70px 0 135px 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.shopify-buy__cart-scroll--discounts {
  padding-bottom: 170px;
}
.shopify-buy__cart-scroll--cart-note {
  padding-bottom: 200px;
}
.shopify-buy__cart-scroll--cart-note.shopify-buy__cart-scroll--discounts {
  padding-bottom: 230px;
}
.shopify-buy__cart-items {
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  position: relative;
  padding: 0 20px 20px;
  -webkit-overflow-scrolling: touch;
  -webkit-perspective: 400px;
  perspective: 400px;
  -webkit-perspective-origin: 50% 0px;
  perspective-origin: 50% 0px;
}
.shopify-buy__cart-item {
  min-height: 65px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation: 200ms flipIn forwards;
  animation: 200ms flipIn forwards;
}
.shopify-buy__cart-item.is-hidden {
  -webkit-animation-name: flipOut;
  animation-name: flipOut;
}
.shopify-buy__cart-item__image {
  width: 65px;
  height: 65px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
}
.shopify-buy__cart-item__title {
  font-size: 14px;
  margin-left: 80px;
  margin-bottom: 3px;
  display: block;
}
.shopify-buy__cart-item__price {
  float: right;
  font-size: 14px;
  font-weight: bold;
  line-height: 26px;
}
.shopify-buy__cart-item__price-and-discounts {
  float: right;
  text-align: right;
  max-width: 100px;
}
.shopify-buy__cart-item__price-and-discounts .shopify-buy__cart-item__price {
  float: none;
}
.shopify-buy__cart-item__full-price {
  font-size: 12px;
  line-height: 12px;
}
.shopify-buy__cart-item__discount {
  font-size: 12px;
  word-wrap: break-word;
  text-transform: uppercase;
}
.shopify-buy__cart-item__discount + .shopify-buy__cart-item__discount {
  margin-top: 5px;
}
.shopify-buy__cart-item__discount__icon {
  width: 12px;
  height: 12px;
  vertical-align: top;
  fill: currentColor;
}
.shopify-buy__cart-item__variant-title {
  margin-left: 80px;
  margin-bottom: 10px;
  color: #4c4c4c;
  font-size: 12px;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.shopify-buy__cart-bottom {
  background-color: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 15px 20px 20px 20px;
}
.shopify-buy__cart__subtotal__text {
  text-transform: uppercase;
  float: left;
  font-size: 11px;
  color: #4c4c4c;
}
.shopify-buy__cart__subtotal__price {
  float: right;
}
.shopify-buy__cart__discount {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  color: #4c4c4c;
}
.shopify-buy__cart__discount__text {
  font-size: 11px;
  text-transform: uppercase;
  margin-right: 10px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.shopify-buy__cart__discount__text__icon {
  width: 11px;
  height: 11px;
  vertical-align: top;
  fill: currentColor;
}
.shopify-buy__cart__discount__amount {
  font-size: 12px;
  line-height: 12px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.shopify-buy__cart__currency {
  font-size: 12px;
}
.shopify-buy__cart__notice {
  font-size: 11px;
  clear: both;
  padding-top: 10px;
  text-align: center;
  color: #4c4c4c;
}
.shopify-buy__cart__note {
  clear: both;
  padding-top: 10px;
}
.shopify-buy__cart__note__description {
  font-size: 11px;
  color: #4c4c4c;
}
.shopify-buy__cart__note__text-area {
  resize: none;
  font-size: 11px;
  width: 100%;
  color: #4c4c4c;
}
.shopify-buy__cart-empty-text {
  padding: 10px 15px;
  text-align: center;
}
.shopify-buy__btn--cart-checkout {
  clear: both;
  margin-top: 15px;
  width: 100%;
  padding: 10px 5px;
  font-size: 16px;
}
.shopify-buy__quantity-container {
  margin-left: 80px;
  margin-right: 100px;
  height: 26px;
  line-height: 26px;
}
.shopify-buy__quantity-container.is-loading {
  opacity: 0.65;
  pointer-events: none;
}
.shopify-buy__cart-item__quantity-input {
  float: left;
  background: transparent;
}
@media (max-width: 330px) {
  .shopify-buy__cart-item__price-and-discounts {
    max-width: 90px;
  }
  .shopify-buy__quantity-container {
    margin-right: 90px;
  }
}
.shopify-buy__quantity-decrement,
.shopify-buy__quantity-increment {
  color: #4c4c4c;
  display: block;
  height: 30px;
  float: left;
  line-height: 16px;
  font-family: monospace;
  width: 26px;
  padding: 0;
  border: none;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: 1px solid #767676;
  position: relative;
}
.shopify-buy__quantity-decrement svg,
.shopify-buy__quantity-increment svg {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -7px;
  fill: currentColor;
}
.shopify-buy__quantity-decrement {
  border-radius: 3px 0 0 3px;
}
.shopify-buy__quantity-increment {
  border-radius: 0 3px 3px 0;
}
.shopify-buy__quantity {
  color: black;
  width: 45px;
  height: 30px;
  font-size: 16px;
  border: none;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  display: inline-block;
  padding: 0;
  border-radius: 0;
  border-top: 1px solid #767676;
  border-bottom: 1px solid #767676;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.shopify-buy__quantity-container.shopify-buy__quantity-with-btns {
  overflow: hidden;
}
.shopify-buy__quantity-container.shopify-buy__quantity-with-btns
  .shopify-buy__quantity {
  border-left: 0;
  border-right: 0;
  float: left;
}

.shopify-buy-frame--toggle {
  top: inherit !important;
  bottom: 150px;
}

.visuallyhidden {   position: absolute !important;   clip: rect(1px, 1px, 1px, 1px);   padding:0 !important;   border:0 !important;   height: 1px !important;   width: 1px !important;   overflow: hidden; }

.shopify-buy__cart__notice {
  font-family: "Simon", "Courier New", Courier, monospace !important;
  color: #666;
  font-size: 12px;
}

.shopify-buy-cart-wrapper {
  background: #fff;

  .shopify-buy__cart {

    /*.shopify-buy__cart__header {
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;

      .shopify-buy__btn--close {
        font-family: "Simon", "Courier New", Courier, monospace !important;
        text-transform: uppercase;
        &:hover {
          color: #666;
        }
      }
    }*/
  }
}

.shopify-buy__btn {
  text-decoration: underline;
}

.shopify-buy__cart-bottom {
  position: absolute;
  bottom: 0px;
}

.shopify-buy__btn--cart-checkout {
  font-family: "Simon", "Courier New", Courier, monospace !important;
  text-transform: uppercase;
  color: #fff;
  background: #000;
}

.shopify-buy__cart__header {
  background: none;
}

/*.hover\:text-hover:hover {
  font-family: 'Monument', sans-serif;
}*/


a:hover {
  text-decoration: none !important;
}
